@import '../../../globals/variables.scss';

.centerFlex{
    justify-content: center!important;
}
.formContainer{
    padding: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center; 
    justify-content: flex-start;
    flex-direction: column; 
    max-width: 800px;
    margin: 0 auto;
    overflow: hidden;
    // background-color: white;
    .formTitle{
        width:100%;
        margin: 20px 0;  
        .tagline{
            text-align: center;;
            color: lightgrey;
            font-size: 15px;
            font-weight: 100;
            font-style: italic;
            letter-spacing: 1px;;
            display: block;
            margin: 10px auto; 
            span{
                background-color: #4a4a4a;
                padding: 5px 30px;
                display: inline-block;
                border-radius: 2px;
            }
        }
        h1{ 
            font-family: "Gotham book", sans-serif;
            text-align: center;;
            color: white;
            margin:0;
            line-height:1;
            font-weight: 400; 
        }
    } 
    .form{ 
        margin: 0 auto;
        max-width: 100%;    
        width: 100%; 
    }
     
    .progressContainer{
        width: 100%;
        margin: 40px 0;
        // background-color: green;
        .progressBar{
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: center;
            .imgContainer{
                // display: flex;
                // align-items: center; 
                // text-align: center;
                
            }
            img{
                display: block;
                width: 100%;
                height: 100%;
                max-width: 55px;
                margin: 0 10px;
            }
            .progressItem{
                width: 25%;
                // margin-bottom: 50px;
                position: relative;
                &.complete{
                    .numberContainer{
                        background-color: #0ff
                    }
                }
                .numberContainer{
                    display: inline-block;
                    transform:skewX(-10deg);
                    background-color: rgba(255,255,255,0.4);
                    padding: 20px 40px;
                    border-radius: 6px;
                    .number{
                        width: auto;
                        transform:skewX(10deg); 
                        p{
                            font-size: 40px;
                            font-weight: 800;
                            line-height: 1;
                            margin: 0;
                            color: white;
                        }
                    }
                   
                }
                >p {
                    position: absolute;;
                    left: 0;
                    right: 0;
                    text-align: center;;
                    font-size: 14px;
                    color: white;
                    line-height:1.6;
                    letter-spacing: 1.5px;
                    font-weight: 100;
                }
            }
        }

    }
}
.tagline{
    text-align:center;
    color: white;
    letter-spacing: 1px;
    font-weight: 800;
    border-radius: 6px;
    padding: 15px;
    line-height: 1.5;

}
h2{ 
    font-size: 40px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px;
}
@import '../../../globals/variables.scss';

.checkbox{  
    width: 100%;
    max-width:  $inputWidthMax; 
    display: block; 
    box-sizing: border-box; 
    padding: 0px 5px; 
    margin: 0;
    p{
        display: flex;
        align-items: center;
        font-size: 11px;
        text-align: left;
        color: grey;
        cursor:pointer;
        span{
            border-radius: 5px;
            display: inline;
            border: 1px solid grey;
            background-color: white;
            height: 30px;
            width: 30px;
            margin-right: 15px;
            flex-shrink: 0;
            position: relative ;
            &.checked:before{
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                width: 25px;
                height: 25px;
                transform: translate(-50%, -50%);
                background-color: $primary;
                border-radius: 3px;
            }   
            // &.checked{
            //     :before{
            //         content: '';
            //         position: absolute;
            //         left: 50%;
            //         top: 50%;
            //         width: 20px;
            //         height: 20px;
            //         transform: translate(-50%, -50%);
            //         background-color: blue;
            //     }   
            // }
        };
    }
   
} 

span.notice{
    display: block;
    color: red; 
    font-size: 11px;
}
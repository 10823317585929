@import '../../../globals/variables.scss';

@media screen and (max-width: 992px) {  
 
.faqs{  
    padding: 50px 0 ; 
    h2{
        margin-top: 0;
    }
}
    
}
@import '../../../../../globals/variables.scss';
 
.header {  
    height: auto;
    z-index:9999;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    position: absolute;
    width: 100%;
    background-color: transparent;
}

.innerHeader {  
    margin: 0 auto; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 20px 0px;
    ul{
        padding: 0;
        li {
            display: inline-block;
            margin: 0 25px;
            list-style-type: none;
            a{
                text-decoration: none;
                color: white;
                cursor:pointer;
            }
        }
    }
    .logo{
        display: flex;
        align-items: center;
        justify-content: center;;
        a{
            display: flex;
        }
        img{
            margin-right: 40px;
            width: 120px;;
            object-fit: contain;
            max-height: 40px;  
        }
    } 
} 
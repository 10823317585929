@import '../../../globals/variables.scss';

.cta {
    padding: 12px 15px;
    border: none;
    cursor: pointer;
    font-size: 19px;
    font-weight: 800;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 3px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: block;
    box-sizing: border-box;
    max-width: 100%;
    border-radius: 25px;
    text-decoration: none;

    @media screen and (max-width: 992px) {
        font-size: 18px;
        padding: 12px 8px;
        width: 100%;
        font-weight: 800;
        max-width: 100%;
        box-sizing: border-box;
        margin: 0;
        span {
            letter-spacing: 1.2px;
            text-transform: uppercase;
            font-weight: 800;
            text-decoration: none;
        }
    }

    span {
        letter-spacing: 1.2px;
        text-transform: uppercase;
        font-weight: 800;
    }
}

.ctaButton {
    border-radius: 25px;
    background-color: $primary;
    color: white;
    border: none;
    padding: 15px 30px;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 19px;
    cursor: pointer;
    z-index: 999;
    position: relative;
    @media screen and (max-width: 992px) {
        font-size: 17px;
        margin: 0 auto;
        display: block;
        width: 100%;
    }
}

.selectButton {
    padding: 8px 15px;
    border: none;
    cursor: pointer;
    font-size: 19px;
    font-weight: 800;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 3px;
    position: relative;
    width: 100%;
    border: 1px solid $primary;
    color: $primary;
    max-width: 450px;
    margin-bottom: 20px;
    display: inline-block;
    // &:hover{
    //     border:1px solid $primary;
    //     background-color: $primary;
    //     color: white;
    // }
    @media screen and (max-width: 992px) {
        font-size: 18px;
        padding: 12px 8px;
        width: 100%;
        font-weight: 800;
        span {
            letter-spacing: 1.2px;
            font-weight: 800;
        }
    }

    span {
        letter-spacing: 1.2px;
        font-weight: 800;
    }
}

.selectOptionButton {
    position: relative;
    width: 100%;
    font-size: 15px;
    padding: 5px 5px 5px 45px;
    margin-bottom: 10px;
    border: none;
    line-height: 1.3;
    border-radius: 5px;
    cursor: pointer;
    background-color: whitesmoke;
    border: 1px solid whitesmoke;
    color: $primary;
    text-align: left;
    min-width: 48%;
    flex-shrink: 0;
    &:before {
        content: '';
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        height: 18px;
        width: 18px;
        border: 2px solid $primary;
        border-radius: 20px;
    }
    //  &.selected {
    //     color: white;
    //     // background-color: $primary;
    //     border: 1px solid $primary;
    // }
}

.selectedOption {
    // color: white;
    // background-color: $primary;
    border: 1px solid $primary;
    &:after {
        content: '';
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        height: 8px;
        width: 8px;
        border: 2px solid $primary;
        border-radius: 20px;
        background-color: $primary;
    }
}

.optionButton {
    width: 100%;
    font-size: 15px;
    padding: 10px;
    background-color: $primary;
    margin-bottom: 10px;
    border: none;
    color: white;
    line-height: 1.7;
    border-radius: 5px;
    cursor: pointer;
    &.selected {
        color: white;
        background-color: $primary;
        // border: 1px solid $primary;
    }
}

.selected {
    color: white;
    background-color: $primary;
    border: 1px solid $primary;
}

.primary {
    color: white;
    background-color: $primary;
    border: 1px solid $primary;
    @media screen and (max-width: 992px) {
        margin-top: 30px;
    }
}

.orange {
    color: #000000;
    background-color: #eba315;
    border: 1px solid #eba315;
    animation: pulse 2s infinite;
}
.orangeNoAnim {
    color: #ffffff;
    background-color: #eba315;
    border: 1px solid #eba315;
}
.grey {
    color: whitesmoke;
    background-color: #e3e3e3;
    border: 1px solid #e3e3e3;
}

.primaryAnimated {
    color: white;
    background-color: $primary;
    animation: pulse 2s infinite;
}
@keyframes pulse {
    0% {
        transform: scale(0.9);
    }

    60% {
        transform: scale(1);
        box-shadow: 0 0 0 50px rgba($primary, 0);
    }

    100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba($primary, 0);
    }
}

@import '../../../globals/variables.scss';
 
.ourSolution{  
    padding: 120px 0 50px;;
    
    h2{
        font-size: 50px;
        color: white;
        text-align: center;;
        margin-bottom:20px;
    }
    p{
        color: white;
        font-size: 20px;
        margin: 0;
        text-align: center;
    }
    .threeSteps{
        padding: 70px 0;
        display: flex;
        align-items: center;    
        justify-content: space-evenly;
        > div{
            text-align: center;
            p{
                margin: 20px 0;
                font-size: 20px;
                display: flex;
                align-items: center;
                color: white;
                span{
                    width: 25px;
                    height: 25px;
                    font-size: 13px;
                    background-color: #293846;
                    color: white;
                    border-radius: 20px;
                    margin-right: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid white;

                }
            }
        }
    }
}
  
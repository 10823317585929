@import '../../../globals/variables.scss';
 
.faqs{  
    padding: 120px 0 50px;; 
    h2{
        font-size: 50px;
        color: white;
        text-align: center;;
        margin-bottom:20px;
    }
    p, ul, td{
        font-size: 18px;
        line-height: 23px;
        margin: 0;
    }
     
    .faqsInner{
        max-width: 800px;
        margin: 0 auto;
        display: block;
        text-align: left;
        .faqsContainer{
            border: 1px solid rgba(86, 123, 159, .32);
            border-radius: 24px;    
            padding: 30px;
            .qContainer{ 
                color: #dcddde;
                font-family: Gotham, sans-serif;
                font-size: 20px;
                font-weight: 500; 
                border-bottom: 1px solid rgba(255, 255, 255, .12);
                &:first-of-type{  
                    > div:first-of-type{
                        padding-top: 0; 
                    }
                }  
                &:last-of-type{  
                    border-bottom: 0;
                    div:first-of-type{    
                        padding-bottom: 0;
                    }
                }
                > div{
                    cursor: pointer;
                    img{
                        transition: ease-in-out 200ms all
                    }
                    .table{
                        margin: 20px 0;
                        border: 1px solid rgba(228, 235, 243, .3);
                        border-radius: 9px;
                        p{
                            border-bottom: 1px solid rgba(228, 235, 243, .3);
                            display:flex;
                            align-items: center;
                            justify-content: space-between;
                            text-align: center;
                            padding: 20px;
                            span{
                                width: 30%;
                                display: inline;
                            }
                        }
                    }
                    &:first-of-type{      
                        color: #dcddde;
                        font-family: Gotham, sans-serif;
                        font-size: 20px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 15px 0; 
                        cursor: pointer;
                    }
                    &:last-of-type{    
                        height:100%;
                        overflow: hidden;  
                        height: 0;
                        transition: ease-in-out 200ms all;;
                        opacity: 0;
                        ul{ 
                            margin-bottom: 15px;
                        }
                        >p{
                            font-size: 15px;
                            line-height: 23px; 
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    } 
}
  
@import '../../../globals/variables.scss';
 
.didYouFinance{  
    padding: 30px 0;; 
    .didYouFinanceInner{  
        display: flex;  
        align-items: center;
        justify-content: space-between;
        background-color: green;
        padding: 50px;
        border-radius: 40px;
        background: linear-gradient(100deg, #3b4c60, rgba(59, 76, 96, 0));
        box-shadow: 0 0 154px rgba(0, 0, 0, .35);
        h2{
            font-size: 50px;
            color: white; 
            margin-bottom:20px;
        }
        p{
            color: white;
            font-size: 20px;
            margin: 0;
            text-align: center; 
        } 
    } 
}
  
@import '../../../globals/variables.scss';

@media screen and (max-width: 992px) {  
  
.specialist{  
    padding: 30px 0;; 
    .specialistInner{   
        flex-direction: column;
        padding: 20px; 
        > div{
            padding: 20px;
            flex-direction: column;
            &:last-of-type{
              padding-bottom: 0;  
            }
        } 
        p{
            text-align: center;
            font-size: 17px;
            margin: 10px 0 20px;; 
            span{
                display: block;
            }
            &:last-of-type{
                margin-bottom: 0;
            }
        }  
    } 
}
  
    
}
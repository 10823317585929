@import '../../../globals/variables.scss';

@media screen and (max-width: 992px) {  
.testimonial{
    margin: 0px 0 30px;
    position: relative;

    &:last-of-type,
    &:nth-of-type(2),
    &:nth-of-type(5){
        margin: 0;
    }
    &:nth-of-type(3),
    &:nth-of-type(6){
        margin-top: -60px;;
    }
    &:before{
        content: ''; 
        height: 100px;
        width: 100px;
        position: absolute;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .imgContainer{
        height: 70px; 
        width: 70px;
        border-radius: 50%;
        background-color: white;
        img{
            max-width:100%;
            max-height:100%;
        }
    }
    &.bp{
        &:before{ 
            right: 20%;
            bottom: 25px;
            top: auto;
            left: auto;
            max-width: 75px;
            transform: scaleX(-1);
        } 
        .imgContainer{
            margin-top: 15px;
            float: right;
        }
        .testimonialContent{
            box-shadow: 0px -1px 1px 1px rgba(255,255,255,0.3);
            background-color: #293644;
        }
    }
    &.tp{ 
        &:before{            
            max-width: 80px;
            left: 20%;
            top: 30px;
            background-image: url('../../../assets/quoteUp.svg');
        }   
        .imgContainer{
            margin-bottom: 15px
        }
        .testimonialContent{
            box-shadow: 0px 1px 1px 1px rgba(255,255,255,0.3);
            background-color: #2b3847;
        }
    }
}

.testimonialContent{  
    padding: 10px; 
    border-radius: 10px;
    box-sizing: border-box;
    text-align: left;
    // background-color: #2b3847;
    p{
        text-align: left;; 
        margin: 20px 0;
        font-size: 15px;
        color: #dcddde;
        line-height: 1.4;
    }
    .title{
        font-size: 18px;
        margin: 0px;
        span{
            display: block;
            font-weight: 700;
        }
    }
    .author{
        font-size: 20px;
        margin: 0px;
        font-weight: 700;
        span{
            display: block;
            font-weight: 400;
        }
    }

}
  
    
}
@import '../../../globals/variables.scss';
 
.specialist{  
    padding: 30px 0;; 
    .specialistInner{  
        display: flex;  
        align-items: center;
        justify-content: space-between; 
        padding: 20px;
        border-radius: 20px;
        box-shadow: 0 0 154px rgba(0, 0, 0, .35); 
        background-color: #121b22;
        border: 1px solid #333;
        > div{
            padding-left: 40px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        } 
        p{
            color: white;
            font-size: 24px;
            margin: 10px 0 20px;;
            display: block;
            line-height: 1.6;
            letter-spacing: 1px;
            span{
                display: block;
            }
        } 
        img{
            border-radius: 50%;
            max-width: 250px;
        }
    } 
}
  
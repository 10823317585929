@import '../../../globals/variables.scss';

@media screen and (max-width: 992px) {  

   
.whatIsPCP{  
    padding: 50px 0;
    .twoCols{
         display: flex;
         align-items: center;
         justify-content: space-between;
         flex-direction: column-reverse;
         > div{
            width: 100%;
            padding: 0 30px 50px;
            &:last-of-type{
                z-index: 9999;
                position: relative;;
            }
            &:first-of-type{
                z-index: 0;
                &:before {
                    top: auto;
                    bottom: 0;
                }
            }
         }

    }
    h2{
        font-size: 25px;
        color: white;
        text-align: center;
    }
    p{
        text-align: center;
    }
}
  
}
@import '../../../../../globals/variables.scss';


@media screen and (max-width: 992px) {
 
  
    .header {  
        height: auto;
        z-index:9999;
        border-bottom: 1px solid rgba(255,255,255,0.2);
        position: absolute;
        width: 100%;
        background-color: transparent;
        top: 0;
    }

    .innerHeader {  
        margin: 0 auto; 
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 20px 0px;
        position: relative;
        .menuContainer{
            background-color:rgba(0,0,0,.7);
            position: fixed;
            left: 0;
            top: 65px;
            bottom: 0;
            right: 0;
            opacity: 0;
            pointer-events: none;;

            transition: ease-in-out 200ms all;
            &.displayMenu{
                opacity: 1;
                pointer-events: all;


            };
        }
        .menu{ 
            position: fixed;
            left: 0;
            right: 0;
            box-sizing: border-box;
            top: 65px;;
            height: auto;
            z-index: 9999;
            background-color: $secondary;
            ul{
                margin: 0;
                list-style-type: none;
                padding: 15px;
                li{
                    box-sizing: border-box;
                    display: block;
                    margin: 0 0 10px;
                    padding: 20px 0 10px;
                    max-width: 100%;
                    border-bottom: 1px solid #757575;
                    width: 100%;
                    text-transform: uppercase;
                    letter-spacing: 1px;;
                    display: block;
                    color: whitesmoke;
                    &:before{
                        content:'';
                        position: absolute;
                        right: 20px;
                        height: 15px;
                        width: 15px;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;;
                        background-image: url('../../../../../assets/chevron-left.svg');
                        transform: scaleX(-1);
                    }
                    &:last-of-type{
                        margin-bottom:0;
                    }
                }
            }

        }
        .menuButton{ 
            height: 25px;
            width: 40px;
            position:relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            > span, &:before, &:after{
                content: '';
                width: 100%;
                height: 2px;
                background-color: white;
                border-radius: 25px;

            }
        } 
    } 
}
@import '../../../globals/variables.scss';

.previewer{  
    width: 100%;
    max-width: 800px;  //$inputWidthMax; 
    display: flex; 
    height: 0;
    overflow: hidden;
    &.show{
        padding: 20px 0;
        height: auto;
    }
    @media screen and (max-width: 992px) {
        width: 100%;
        display: block;
        max-width:  100%;
        box-sizing: border-box;
    }
    > *{
        // max-width: 300px;
        max-width: 800px;
        width: 100%;
        margin: 0px auto 30px;;
        height: 400px;
        border-radius: 8px;
    }
} 

.previewLink{
    display: block;
    margin: 0 auto 20px;;
    color: white;
    background-color: transparent;
    border: none;
    font-size: 18px;
    letter-spacing: 1px;
    cursor: pointer;
}
.uppercase{
    text-transform: uppercase;
    letter-spacing: 1.3px;
    text-align: center;
    font-weight: 800;
}
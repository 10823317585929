@import '../../../globals/variables.scss';

@media screen and (max-width: 992px) {  
  
    .haveClaim{  
        padding: 10px 0;;   
        .haveClaimInner{  
      
            display: flex;  
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            background-color: green;
            padding: 20px;
            border-radius: 20px;
            background: linear-gradient(100deg, #3b4c60, rgba(59, 76, 96, 0));
            box-shadow: 0 0 154px rgba(0, 0, 0, .35);
            h2{
                font-size: 25px;
                color: white; 
                margin: 0 0 20px;
            }
            p{
                color: white;
                font-size: 20px;
                margin: 0 0 20px;
                text-align: center; 
            }  

    } 

            img{
                margin-top: 0;
                max-width: 70%;
                transform: translateY(18px);
            }
}
  
    
}
@import '../../../globals/variables.scss';

@media screen and (max-width: 992px) {  
 
.benefits{  
    padding: 0 0 0px; 
    h2{
        font-size: 25px;
        color: white;
        text-align: center;;
        margin-bottom:20px;
    }
    p{
        color: white;
        font-size: 20px;
        margin: 0;
        text-align: center;

    }
    .fourBenefits{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin: 30px 0 0 ;
        p{
            text-align:left;
            margin-left: 10px;
        }
        >div{
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;;
            div{
                margin-right: 10px;
                position: relative;
                &:before{
                    width: 1%;
                    height: 17%;
                    background-color: transparent;
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: 90%;
                    border-left: 1px dotted whitesmoke;
                }
            }
            &:last-of-type div:before{
                display: none;
            }
            
            img{
                margin: 0 auto 20px;
                display: block;
                position:relative;
                z-index:1;
                max-width: 90px;

            }
        }
    }
    .carWithCta{
        padding: 0 15px;
        display: flex;
        align-items: center; 
        img{
            display:none;
        }
        button{ 
            position: relative;
            left:auto;
            right: auto;
            max-width: 100%;
            box-sizing: border-box;
        }
    }
}
  
}
@import '../../../../../globals/variables.scss'; 

.footer{
    // margin-top: 40px; 
    padding: 40px 20px;
    color: white; 
    img{
        max-width: 120px;
    }
    
    .innerFooter{   
        nav{

            ul{
                list-style: none;
                padding:0;
                display: flex;
                li{
                    margin-right: 20px;
                    a{
                        color: white;
                        text-decoration: none;;
                    }
                }
            }
        }
        p{
            color: #dcddde;
            margin-bottom: 0;
            font-size: 18px;
            line-height: 25px;
        }
        .links a{
            font-size: 18px;
            margin-bottom: 5px;
            display: block;
            padding: 5px;
            color: #0ff;

        }
    }
    hr{
        border-color: #848484;
    }
    .footerbottom {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .logoContainer{
            display: block;
            margin: 0 auto;
            text-align: center;
            p{
                margin: 10px ;
                color:#646472
            }
        }
        .terms a {
            color: white
        }
        >div{
            display: flex;
            justify-content: center;;
            p{
                margin: 10px ;   
            }
        }
    }
}
 
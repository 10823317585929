@import '../../../globals/variables.scss';

@media screen and (max-width: 992px) { 
    .formContainer{
        padding-top: 0;
        .formTitle{
            margin: 10px 0; 
            p{ 
                line-height: 1.7; 
                padding: 3px 15px; 
                font-size: 12px; 
            } 
            h1{
                font-size: 19px;
                margin-bottom: 0;
            }
        }
          
    .progressContainer{
        width: 100%;
        margin: 20px 0;
        .progressBar{

            > div{ 
                img{ 
                    max-width: 15px;
                    margin: 0px;
                }
                .progressItem{ 
                    .numberContainer{ 
                        padding: 10px;
                        .number{ 
                            p{
                                font-size: 10px; 
                            }
                        } 
                    }
                    >p { 
                        font-size: 10px; 
                        padding: 0;
                        letter-spacing: 1px;
                    }
                }
            }
        }

    }
    }
}
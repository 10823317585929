@import '../../../globals/variables.scss';
 
.lawPartners{  
    padding: 120px 0 50px;; 
    h2{
        font-size: 50px;
        color: white;
        text-align: center;;
        margin-bottom:20px;
    }
    p{
        color: white;
        font-size: 20px;
        margin: 0;
        text-align: center; 
    }
    .fourPoints{
        display: flex;
        align-items: flex-start;
        margin: 50px 0;
        >div{
            position: relative;
            width: 25%;
            text-align:center; 
            
            img{
                margin: 0 auto 20px;
                display: block;
                position:relative;
                z-index:1; 
            }
        }
    } 
}
  
@import '../../../globals/variables.scss';


.contactForm{  
    padding-top: 100px;
    min-height: calc(100vh - 500px);
    display: flex;
    align-items: center;;
    .contactInner{
        padding: 100px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > div{
            padding: 0 20px;
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            p{
                font-size: 19px;
                color: #dcddde;;
                line-height: 1.6;
                letter-spacing: 1px;;
                font-family: 'Gotham book', sans-serif;
            }
            &:last-of-type{
                form{
                    height: 100%;

                }
            }
            input, textarea{
                display: block;
                width: 100%;
                margin-bottom: 15px;
                box-sizing: border-box;
                font-size: 15px;
                border-radius: 20px;
                margin-bottom: 15px;
                padding: 20px  15px;
                font-family: 'Gotham book', sans-serif;


                &::placeholder {
                    font-family: 'Gotham book', sans-serif;

                }

            }
            textarea{
                min-height: 250px;
            }
        }
    }
}
  
@import '../../../globals/variables.scss';
 
.haveClaim{  
    padding: 30px 0;; 
    .haveClaimInner{  
        display: flex;  
        align-items: center;
        justify-content: space-between;
        background-color: green;
        padding: 50px;
        border-radius: 40px;
        background: linear-gradient(100deg, #3b4c60, rgba(59, 76, 96, 0));
        box-shadow: 0 0 154px rgba(0, 0, 0, .35);
        h2{
            font-size: 50px;
            color: white; 
            margin-bottom:10px;
            margin-top: 0;
        }
        p{
            color: white;
            font-size: 20px;
            line-height: 1.4;
            margin: 0 0 20px; 

        } 
    } 
    img{
        max-width: 30%;
        transform: translateY(50px);
    }
}
  
@import '../../../globals/variables.scss';

@media screen and (max-width: 992px) {  
 
.knowAboutPcp{  
    padding: 30px 0;
    .twoCols{
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: space-between;
         > div{
            width: 100%;
            padding: 0 30px;
         }
    }
    h2{
        font-size: 25px;
        text-align: center;;
    }
    .facts{
        display: flex;
        flex-wrap: wrap;;
        .fact{
            box-sizing: border-box;
            width: 100%;
            padding: 10px;
           
        }
    }
}
  
    
}
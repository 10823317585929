@import '../../../globals/variables.scss';
 
.ourClients{  
    padding: 120px 0 50px;; 
    h2{
        font-size: 50px;
        color: white;
        text-align: center;;
        margin-bottom:20px;
    }
    p{
        color: white;
        font-size: 20px;
        margin: 0;
        text-align: center;

        &:last-of-type{
            text-align: left; 
            font-size: 14px;
        }
    }
    .clients{
        padding: 70px 0;
        display: flex;
        align-items: center;    
        justify-content: space-evenly;
        flex-direction: row;
        flex-wrap: wrap;
        > div{
            max-width: 30%;
            
        }
    }
}
  
@import '../../../globals/variables.scss';

@media screen and (max-width: 992px) {  
  
.ourSolution{  
    padding: 50px 0 ;
    h2{
        font-size: 25px; 
        margin-bottom:20px;
    } 
    .threeSteps{
        padding: 40px 0;
        display: flex;
        align-items: center;    
        justify-content: space-evenly;
        flex-direction: column;
        > div{
            text-align: center;
            p{
                margin: 20px 0;
                font-size: 20px;
                display: flex;
                align-items: center;
                color: white;
                span{
                    width: 25px;
                    height: 25px;
                    font-size: 13px;
                    background-color: #293846;
                    color: white;
                    border-radius: 20px;
                    margin-right: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid white;

                }
            }
        }
    }
}
  
}
@import '../../../globals/variables.scss';
 
.whatIsPCP{  
    padding: 120px 0 50px;;
    .twoCols{
         display: flex;
         align-items: center;
         justify-content: space-between;
         > div{
            width: 50%;
            padding: 0 30px;
         }
    }
    h2{
        font-size: 50px;
        color: white;
    }
    .textContent{
        color: white;
        p{
            font-size: 19px;
            color: #dcddde;
            line-height:1.4;
        }
        ul{
            list-style-type: none;
            padding: 0;
            li{
                padding-left: 55px;
                font-size: 18px;
                margin-bottom: 25px;
                position: relative;
                line-height: 1.4;
                color: #dcddde;
                &:before{
                    content: '';
                    opacity:0.7;
                    height: 40px;
                    width: 40px;
                    position: absolute;
                    left:0;
                    top:50%;
                    transform:translateY(-50%);
                    background-image: url('../../../assets/warning.svg');
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
    }
    .imgContent{
        position: relative;
        img{
            max-width: 500px;
            position: relative;
            display: block;
            z-index: 1;
        }
        &:before{
            content: '';
            background-color: #38485b;
            height: 150%;
            width: 400px;
            position: absolute;
            z-index: 0;
            right: 50%;
            top: -25%;
        }
    }
}
  
@import '../../../globals/variables.scss';
 
.knowAboutPcp{  
    padding: 120px 0 50px;;
    .twoCols{
         display: flex;
         align-items: center;
         justify-content: space-between;
         > div{
            width: 50%;
            padding: 0 30px;
         }
    }
    h2{
        font-size: 50px;
        color: white;
    }
    .facts{
        display: flex;
        flex-wrap: wrap;;
        .fact{
            box-sizing: border-box;
            width: 50%;
            padding: 10px;
            .innerFact{
                border-radius: 15px;
                background-color: #2b3847;
                padding: 20px;
                box-sizing: border-box;
                height: 100%;
                h3{
                    color: white;
                    font-weight:600;
                    font-size: 22px;
                    margin: 20px 0;
                }
                > p{
                    margin: 0;
                    font-size: 19px;
                    color: white;
                    &:first-of-type{
                        display: inline-block;
                        border-top: 1.5px solid #a38f72;
                        border-bottom: 1.5px solid #a38f72;
                        color: white;
                        padding: 10px 0;
                        margin: 0;
                    }
                    &:last-of-type{
                        line-height: 1.4;
                        color: #dcddde;
                    }

                }
            }
        }
    }
}
  
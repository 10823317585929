 @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;700&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&display=swap');

 html {
   overflow-x: hidden;
   max-width: 100vw;
 }

 body {
   overflow-x: hidden;
   max-width: 100vw;
   margin: 0;
   padding: 0;
   color: #333;
   background-color: #121b22;
   font-family: Gotham book, sans-serif;

   * {
     font-family: 'Montserrat', sans-serif;

   }
 }
 .faqVisible > div:last-of-type{ 
  max-height: 400px!important;
  transition:ease-in-out 200ms;
 }

.react-datepicker-wrapper {
  max-width: 100%;
  width: 100%;  
}
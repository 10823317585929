@import '../../../../../globals/variables.scss';
@media screen and (max-width: 992px) {
    .footer {
        padding: 30px 0;
        color: white;

        .innerFooter {
            width: 100%;
            max-width: $wrapperMaxWidth;
            margin: 0 auto;
            p {
                a {
                    display: inline-block;
                    margin-right: 20px;
                }
            }
            ul {
                list-style-type: none;
                padding: 0;
            }
        }
        .terms {
            flex-direction: column;
            p {
                text-align: center;
                display: block;
                width: 100%;
            }
        }
    }
}

@import '../../../globals/variables.scss';

.hero {
    background-image: linear-gradient(315deg, rgba(51, 75, 92, 0.65), #16202a 25%, #11191f 50%, rgba(25, 37, 49, 0.61) 75%, rgba(56, 82, 114, 0.61));
    background-position: 0 0;
    background-repeat: repeat;
    background-size: auto;

    .heroContent {
        // min-height: 100vh;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        z-index: 1;
        text-align: left;
        padding: 120px 0 60px;
        background-size: 80%;
        background-position: left center;
        background-repeat: no-repeat;
        // background-image: url('../../../assets/car-hero.png');
        .lines {
            position: absolute;
            top: 19%;
            width: 100%;
            left: 0;
            z-index: 1;
        }
        &:before {
            content: '';
            opacity: 0.5;
            position: absolute;
            right: 0;
            top: 6%;
            width: 550px;
            height: 50vh;
            z-index: 0;
            background-size: contain;
            background-position: left center;
            background-repeat: no-repeat;
            background-image: url('../../../assets/background-logo.png');
        }
        > img {
            max-width: 90%;
            transform: translateX(-10%);
            z-index: 2;
        }
        > h1 {
            color: whitesmoke;
            padding: 0 20px;
            font-size: 65px;
            margin: 0;
            z-index: 999;
            display: block;
            position: relative;
            span {
                color: #a2a7b0;
            }
        }
        > p {
            color: white;
            font-size: 14px;
            margin: 10px 0 10px;
            line-height: 1.3;
            font-style: italic;
        }
        ul {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            list-style-type: none;
            z-index: 9999;
            li {
                margin-bottom: 20px;
                font-size: 18px;
                color: #a2a7b0;
                cursor: pointer;
            }
        }
        .tagLine {
            display: flex;
            > div {
                margin: 0 20px;
                color: white;
                p {
                    font-size: 20px;
                }
                &:after {
                    content: '';
                    height: 2px;
                    width: 50px;
                    background-color: white;
                    display: block;
                }
            }
        }
        //    > span{
        //         margin: 0;
        //         line-height: 1.7;
        //         color: #438f68;
        //         width: auto;
        //         background: #11001d;
        //         color: white;
        //         padding: 3px 15px;
        //         display: inline-block;
        //         font-size: 14px;
        //         margin-bottom: 10px;
        //         border-radius: 20px;
        //     }
    }
}

$primary:#3898ec;
$secondary: #364d61;

$darkgreenGradient: linear-gradient(90.08deg, #D5B891 1.32%, #A4713F 100.87%);
$wrapperMaxWidth: 1200px;
$timing: 1500ms;
$bigtiming: 1750ms;
$inputWidth: 100%;
$inputWidthMax:900px;


@import '../../../globals/variables.scss';

@media screen and (max-width: 992px) {
    .hero {
        .heroContent {
            // min-height: 100vh;
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            z-index: 1;
            text-align: left;
            padding: 80px 0;
            // background-image: url('../../../assets/car-hero.png');
            .lines {
            }
            &:before {
                top: auto;
                right: 0;
                bottom: 15%;
                left: auto;
                width: 80%;
                z-index: 0;
                background-size: contain;
                background-position: right center;
                background-repeat: no-repeat;
            }
            > img {
            }
            > h1 {
                padding: 0;
                font-size: 35px;
                span {
                    color: #a2a7b0;
                }
            }
            > p {
                color: white;
                font-size: 14px;
                margin: 10px 0 10px;
                line-height: 1.3;
                font-style: italic;
            }
            ul {
                display: none;
                li {
                    margin-bottom: 20px;
                    font-size: 18px;
                    color: #a2a7b0;
                    cursor: pointer;
                }
            }
            .tagLine {
                display: flex;
                flex-direction: column;
                > div {
                    width: 100%;
                    margin: 0;
                    color: white;
                    p {
                        font-size: 16px;
                        margin: 5px 0 10px;
                    }
                    &:after {
                        margin-bottom: 20px;
                        background-color: #858585;
                    }
                }
            }
            //    > span{
            //         margin: 0;
            //         line-height: 1.7;
            //         color: #438f68;
            //         width: auto;
            //         background: #11001d;
            //         color: white;
            //         padding: 3px 15px;
            //         display: inline-block;
            //         font-size: 14px;
            //         margin-bottom: 10px;
            //         border-radius: 20px;
            //     }
        }
    }
}

// .inputContainer{
//     display: flex;
//     justify-content: space-between;
// }
@import '../../../globals/variables.scss';

.fileInputContainer p {
    font-size: 13px;
    margin: 0;
    max-width: 100%;
}

.fileLabel {
    position: relative;
    display: block;
    height: 200px;
    width: 100%;
    background: #363636;
    border-radius: 4px;
    margin-bottom: 15px;
    cursor: pointer;
    border: 1px solid $primary;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    span {
        color: #848484;
        position: absolute;
        bottom: -15%;
        left: 0;
        right: 0;
        font-weight: 300;
        font-size: 15px;
        text-align: center;
        font-style: italic;
    }

    img {
        object-fit: cover;
        max-width: 99%;
        max-height: 99%;
        pointer-events: none;
    }
    @media screen and (max-width: 992px) {
        height: 200px;
        width: 100%;
    }
}
input[type='file'] {
    visibility: hidden;
    opacity: 0;
    width: 1px;
    height: 1px;
}

@import '../../../globals/variables.scss';

@media screen and (max-width: 992px) {  
 
.lawPartners{  
    padding: 40px 0; 
    h2{
        font-size: 25px;
        color: white;
        text-align: center;;
        margin-bottom:20px;
    }
    p{
        color: white;
        font-size: 20px;
        margin: 0;
        text-align: center; 
    }
    .fourPoints{
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
        flex-wrap: wrap;
        >div{
            position: relative;
            width: 50%;
            text-align:center; 
            
            img{
                margin: 0 auto;
                max-width: 80%;
            }
        }
    } 
}
  
    
}
@import '../../../globals/variables.scss';
 


.testimonial{
    margin: 0px 0 30px;
    position: relative;
    &:before{
        content: ''; 
        height: 100px;
        width: 100px;
        position: absolute;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .imgContainer{
        height: 150px; 
        width: 150px;
        border-radius: 50%;
        background-color: white;
        img{
            max-width:100%;
            max-height:100%;
        }
    }
    &.bp{
        &:before{ 
            left: 40%;
            bottom: 140px;
            background-image: url('../../../assets/quoteDown.svg');
        } 
        .imgContainer{
            margin-top: 50px
        }
        .testimonialContent{
            box-shadow: 0px -1px 1px 1px rgba(255,255,255,0.3);
            background-color: #293644;
        }
    }
    &.tp{ 
        &:before{
            left: 30%;
            top: 150px; 
            background-image: url('../../../assets/quoteUp.svg');
        }   
        .imgContainer{
            margin-bottom: 50px
        }
        .testimonialContent{
            box-shadow: 0px 1px 1px 1px rgba(255,255,255,0.3);
            background-color: #2b3847;
        }
    }
}

.testimonialContent{  
    padding: 30px; 
    border-radius: 20px;
    box-sizing: border-box;
    text-align: left;
    // background-color: #2b3847;
    p{
        text-align: left;; 
        margin: 20px 0;
        font-size: 17px;
        color: #dcddde;
        line-height: 1.4;
    }
    .title{
        font-size: 20px;
        margin: 0px;
        span{
            display: block;
            font-weight: 700;
        }
    }
    .author{
        font-size: 20px;
        margin: 0px;
        font-weight: 700;
        span{
            display: block;
            font-weight: 400;
        }
    }

}
  
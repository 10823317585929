@import '../../../globals/variables.scss';
 
.benefits{  
    padding: 50px 0 ; 
    h2{
        font-size: 50px;
        color: white;
        text-align: center;;
        margin-bottom:20px;
    }
    p{
        color: white;
        font-size: 20px;
        margin: 0;
        text-align: center;

    }
    .fourBenefits{
        display: flex;
        align-items: flex-start;
        margin: 50px 0;
        >div{
            position: relative;
            width: 25%;
            text-align:center;
            div{
                position: relative;
                &:before{
                    content:''; 
                    width: 62%;
                    height: 1px;
                    background-color: transparent;
                    display: block;
                    position: absolute;
                    left: 69%;
                    top: 50%;
                    transform: translateY(-50%);
                    border-top: 1px dotted white;
                    z-index:0;
                }
            }
            &:last-of-type div:before{
                display: none;
            }
            
            img{
                margin: 0 auto 20px;
                display: block;
                position:relative;
                z-index:1;

            }
        }
    }
    .carWithCta{
        display: flex;
        align-items: center;
        @media screen and (max-width: 992px) {  
            align-items: center;
            justify-content: center;
        } 
        button{
            @media screen and (max-width: 992px) {  
                position: absolute;
                right: 0;
            }
        }
    }
}
  
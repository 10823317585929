@import '../../../globals/variables.scss';

@media screen and (max-width: 992px) {  
 
.ourClients{  
    padding: 0 0 50px; 
    h2{
        font-size: 25px;
        color: white;
        text-align: center;;
        margin-bottom:20px;
    }
   
    .clients{
        padding: 40px 0;
        display: flex;
        align-items: center;    
        justify-content: space-evenly;
        flex-direction: column;
        flex-wrap: no-wrap;
        > div{
            max-width: 100%;
            width: 100%;
            
        }
    }
}
  
}
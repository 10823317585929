.question {
    text-align: center;
    color: white;
    letter-spacing: 1px;
    font-weight: 800;
    border-radius: 6px;
    padding: 5px 15px;
    line-height: 1.5;
}
.normal {
    color: white;
    text-align: center;
}

.normalList {
    color: whitesmoke;
    // max-width: 75%;
    padding-left: 5px;
    margin: 0 auto 0 20px;
    display: flex;

    justify-content: space-between;
    flex-direction: column;
    li {
        margin-bottom: 10px;
    }
}
